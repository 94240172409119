/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
 import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/reflect';


/**
 * Required to support Web Animations `@angular/platform-browser/animations`.
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.



/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/de';
(function(view) {
    "use strict";
    var
          Uint8Array = view.Uint8Array
        , HTMLCanvasElement = view.HTMLCanvasElement
        , canvas_proto = HTMLCanvasElement && HTMLCanvasElement.prototype
        , is_base64_regex = /\s*;\s*base64\s*(?:;|$)/i
        , to_data_url = "toDataURL"
        , base64_ranks
        , decode_base64 = function(base64) {
            var
                  len = base64.length
                // tslint:disable-next-line:no-bitwise
                , buffer = new Uint8Array(len / 4 * 3 | 0)
                , i = 0
                , outptr = 0
                , last = [0, 0]
                , state = 0
                , save = 0
                , rank
                , code
                , undef
            ;
            while (len--) {
                code = base64.charCodeAt(i++);
                rank = base64_ranks[code - 43];
                if (rank !== 255 && rank !== undef) {
                    last[1] = last[0];
                    last[0] = code;
                    // tslint:disable-next-line:no-bitwise
                    save = (save << 6) | rank;
                    state++;
                    if (state === 4) {
                        // tslint:disable-next-line:no-bitwise
                        buffer[outptr++] = save >>> 16;
                        if (last[1] !== 61 /* padding character */) {
                            // tslint:disable-next-line:no-bitwise
                            buffer[outptr++] = save >>> 8;
                        }
                        if (last[0] !== 61 /* padding character */) {
                            buffer[outptr++] = save;
                        }
                        state = 0;
                    }
                }
            }
            // 2/3 chance there's going to be some null bytes at the end, but that
            // doesn't really matter with most image formats.
            // If it somehow matters for you, truncate the buffer up outptr.
            return buffer;
        }
    ;
    if (Uint8Array) {
        base64_ranks = new Uint8Array([
              62, -1, -1, -1, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1
            , -1, -1,  0, -1, -1, -1,  0,  1,  2,  3,  4,  5,  6,  7,  8,  9
            , 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25
            , -1, -1, -1, -1, -1, -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35
            , 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51
        ]);
    }
    if (HTMLCanvasElement && (!canvas_proto.toBlob || !canvas_proto.toBlobHD)) {
        if (!canvas_proto.toBlob){


        canvas_proto.toBlob = function(callback, type /*, ...args*/) {
              if (!type) {
                type = "image/png";
            } if (this.mozGetAsFile) {
                callback(this.mozGetAsFile("canvas", type));
                return;
            } if (this.msToBlob && /^\s*image\/png\s*(?:$|;)/i.test(type)) {
                callback(this.msToBlob());
                return;
            }

            var
                  args = Array.prototype.slice.call(arguments, 1)
                , dataURI = this[to_data_url].apply(this, args)
                , header_end = dataURI.indexOf(",")
                , data = dataURI.substring(header_end + 1)
                , is_base64 = is_base64_regex.test(dataURI.substring(0, header_end))
                , blob
            ;
            if ((<any>Blob).fake) {
                // no reason to decode a data: URI that's just going to become a data URI again
                blob = new Blob;
                if (is_base64) {
                    blob.encoding = "base64";
                } else {
                    blob.encoding = "URI";
                }
                blob.data = data;
                blob.size = data.length;
            } else if (Uint8Array) {
                if (is_base64) {
                    blob = new Blob([decode_base64(data)], {type: type});
                } else {
                    blob = new Blob([decodeURIComponent(data)], {type: type});
                }
            }
            callback(blob);
        };
    }
        if (!canvas_proto.toBlobHD && canvas_proto.toDataURLHD) {
            canvas_proto.toBlobHD = function() {
                to_data_url = "toDataURLHD";
                var blob = this.toBlob();
                to_data_url = "toDataURL";
                return blob;
            };
        } else {
            canvas_proto.toBlobHD = canvas_proto.toBlob;
        }
    }
    }(typeof self !== "undefined" && self || typeof window !== "undefined" && window || this.content || this));

